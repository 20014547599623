.dw {
    padding-top: 1rem;
    height: calc(100% - 1rem);

    &-head {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    &-workspace {
        margin-top: 1rem;
        height: calc(100% - 6rem);
        display: flex;
    }
}
