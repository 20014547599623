$toast_Success: #00d68f;
$toast_Warning: #ffaa00;
$toast_Error: #ff1956;

.toast {
    width: 22.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    border-radius: 0.25rem;
    z-index: 90;
    position: relative;

    &-Success {
        background-color: $toast_Success;
    }

    &-Warning {
        background-color: $toast_Warning;
    }

    &-Error {
        background-color: $toast_Error;
    }

    &-left {
        display: flex;
        align-items: center;

        &-icon-container {
            background-color: #fff;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &-icon {
                &-Success {
                    fill: $toast_Success;
                }

                &-Warning {
                    fill: $toast_Warning;
                }

                &-Error {
                    fill: $toast_Error;
                }
            }
        }
    }

    &-content {
        margin-left: 1rem;
        font-style: normal;
        color: #fff;

        &-type {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.5rem;
        }

        &-message {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
        }
    }

    &-right {
        margin-right: 0px;
        margin-left: auto;
        align-items: flex-end;

        &-action-icon {
            fill: #fff;
            cursor: pointer;
        }

        &-close-container {
            position: relative;
            width: 1.25rem;
            height: 100%;

            &-extended {
                width: 2.5rem;

                &-icon {
                    left: unset !important;
                    right: 4px;
                }
            }

            &-icon {
                position: absolute;
                top: 4px;
                left: 4px;
            }
        }
    }

    &-extended {
        box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    }
}

.toast-extension {
    height: 2.5rem;
    margin-top: -0.625rem;
    border-radius: 0.25rem;
    display: flex;

    &-Error {
        background-color: #de003b;
    }

    &-content {
        margin-top: 0.625rem;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        line-height: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;

        &-title {
            font-size: 0.75rem;
            color: #fff;
        }

        &-message {
            background-color: #fff;
            margin-left: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: 0.125rem;
            font-size: 0.6875rem;
        }
    }
}
