.toasts {
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 0.75rem;
    z-index: 100;

    &-item {
        margin-top: 0.25rem;
    }
}
